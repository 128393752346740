<template>
    <el-table v-if="data.length > 0" :data="data" default-expand-all>
        <el-table-column :label="$t('clients.custom_id')" prop="custom_id" sortable width="120" />
        <el-table-column :label="$t('clients.name')" prop="name" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link :to="`/clients/${scope.row.uuid}/dashboard`" class="hover:text-red-500 hover:font-semibold">
                    {{ nameFormatter(scope.row) }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.email')" prop="email" sortable />
        <el-table-column :label="$t('clients.manager')" prop="manager" sortable />
        <el-table-column :label="$t('clients.addresses')" prop="addresses_count" sortable width="110" align="center" />
        <el-table-column :label="$t('clients.keys')" prop="keys_count" sortable width="100" align="center" />
        <el-table-column :label="$t('clients.type')" prop="type" sortable width="100" :formatter="typeFormatter" />
        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link :to="`/clients/${scope.row.uuid}/dashboard`" class="el-button el-tooltip el-button--primary el-button--mini">
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.archive')" placement="top">
                        <el-button type="warning" size="mini" @click="handleArchive(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'archive']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
            <template slot-scope="props">
                <div class="space-y-2">
                    <div v-for="item in props.row.addresses" :key="item.id" class="flex w-full justify-between">
                        <div class="flex">
                            <div class="flex items-center mr-2">
                                <fa-icon :icon="['fas', 'level-up-alt']" fixed-width rotation="90" class="text-gray-400" />
                            </div>

                            <router-link :to="`/clients/${props.row.uuid}/addresses/${item.uuid}`" class="hover:text-red-500 hover:font-semibold">
                                <span v-if="item.title" class="font-bold">{{ item.title }}:</span> {{ item.street }}, {{ item.city }} {{ item.post_code }}
                            </router-link>
                        </div>
                        <p v-if="userIsBuildingCompany() && item.status">
                            {{ $t(`clients.${item.status}`) }}
                        </p>
                    </div>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        nameFormatter(row) {
            return [row.name, row.surname].join(' ');
        },

        typeFormatter(row) {
            return this.$t(`clients.${row.type}`);
        },

        handleArchive(value) {
            this.$emit('handleArchive', value);
        },
    },
};
</script>
<style>
.el-table_1_column_1.el-table__expand-column {
    display: none !important;
}
.el-table__expanded-cell {
    padding: 10px 20px !important;
    padding-left: 110px !important;
}
</style>
